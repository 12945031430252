
import { ref, reactive, defineComponent, computed } from 'vue';

import { useToast } from 'primevue/usetoast';
import Dialog from 'primevue/dialog';
import Button from 'primevue/button';

import { WriteOffApi } from '@/classes/api/WriteOffApi';
import WriteOffFormFields from '@/constants/FormFields/writeoff';
import { FormFields, FormStructure } from '@/interfaces/Input';
import { FormHandler } from '@/classes/forms/FormHandler';
import { MESSAGES } from '@/constants/errors';
import { WriteOff } from '@/classes/models/WriteOff';
import CustomError from '@/classes/models/CustomError';

import WriteOffFormLayout from './WriteOffFormLayout.vue';
import FlexInput from '@/components/UI/Input.vue';
import WriteOffPartsList from './WriteOffPartsList.vue';

export default defineComponent({
  name: 'WriteOffForm',
  emits: ['update:visible', 'onSubmit', 'update:editMode'],
  components: {
    Dialog,
    Button,
    'flex-input': FlexInput,
    'writeoff-parts-list': WriteOffPartsList,
    'writeoff-form-layout': WriteOffFormLayout,
  },
  props: {
    visible: Boolean,
    id: Number,
    editMode: Boolean,
  },

  setup(props, ctx) {
    const loading = ref(false);
    const item = reactive<WriteOff>(new WriteOff({}));
    const fields = reactive<FormFields>({ ...WriteOffFormFields });
    const posted = ref(false);
    const saved = ref(false);

    const formHandler = new FormHandler(fields, item);
    const formStructure: FormStructure = formHandler.createFormStructure();

    const toast = useToast();
    const api = new WriteOffApi();

    const inputVisible = computed({
      get: () => props.visible,
      set: val => {
        ctx.emit('update:visible', val);
      },
    });

    const inputEditMode = computed({
      get: () => props.editMode,
      set: val => {
        ctx.emit('update:editMode', val);
      },
    });

    const setItem = (itm: WriteOff) => {
      item.replaceBy(itm);
      if (itm.documentDate) {
        item.documentDate = new Date(itm.documentDate);
      } else {
        item.documentDate = new Date();
      }
      formHandler.setObjectToValidate(item);
    };

    const isAnyEmptyLines = (): boolean => {
      let result = false;
      if (item.lines) {
        result = item.lines.some(line => !line.partId);
        if (result) {
          toast.add({
            severity: 'warn',
            summary: 'Предупреждение!',
            detail:
                'Обнаружены незаполненные товары. Для продолжения заполните или удалите пустые позиции.',
            life: 3000,
          });
        }
      }
      return result;
    };

    const resetValidations = () => {
      Object.values(fields).forEach(field => {
        field.invalid = false;
        field.validationErrors = [];
      });
    };

    const resetFormHandler = () => {
      setItem(new WriteOff({}));
      resetValidations();
    };

    const fetch = async () => {
      if (inputEditMode.value && props.id) {
        loading.value = true;
        try {
          setItem(await api.fetchById(props.id));
        } catch (error) {
          if (error instanceof CustomError) {
            error.show('error', 5000, toast);
          } else {
            console.log(error);
          }
        }
        loading.value = false;
      } else {
        resetFormHandler();
      }
    };

    const isPosted = (target: WriteOff): boolean => {
      if (target.status) {
        if (target.status === 'Posted') {
          return true;
        }
      }
      return false;
    };

    const isSaved = (target: WriteOff): boolean => {
      return !!target.id;
    };

    const onShow = async () => {
      loading.value = true;
      item.documentDate = new Date();
      await fetch();
      loading.value = false;
      posted.value = isPosted(item);
      saved.value = isSaved(item);
    };

    const saveDocument = async (post = false, close = false) => {
      const valid = await formHandler.checkValidations();
      let message = 'Успешно добавлено';
      if (valid && !isAnyEmptyLines()) {
        loading.value = true;
        try {
          if (!inputEditMode.value) {
            const savedItem = await api.send(item);
            item.replaceBy(savedItem);
            saved.value = true;
            inputEditMode.value = true;
            if (post) {
              if (savedItem.id) {
                await api.post(savedItem.id);
                posted.value = true;
              }
            }
          } else {
            const savedItem = await api.update(item);
            message = 'Успешно изменено';
            item.replaceBy(savedItem);
            saved.value = true
            if (post) {
              if (savedItem.id) {
                await api.post(savedItem.id);
                posted.value = true;
              }
            }
          }
          ctx.emit('onSubmit');
          if (close) {
            resetFormHandler();
            inputVisible.value = false;
          }
          toast.add({
            severity: 'success',
            summary: 'Успешно',
            detail: message,
            life: 3000,
          });
        } catch (error) {
          if (error instanceof CustomError) {
            error.show('error', 5000, toast);
          } else {
            console.log(error);
          }
        }
        loading.value = false;
      } else {
        toast.add({
          severity: 'warn',
          summary: 'Некорректное заполнение',
          detail: MESSAGES.generalFormErrors,
          life: 3000,
        });
      }
    };

    const submitHandler = () => {
      saveDocument();
    };

    const savePostDocumentHandler = () => {
      saveDocument(true, true);
    };

    const postHandler = async () => {
      if (inputEditMode.value || saved.value) {
        loading.value = true;
        try {
          if (item.id) {
            await api.post(item.id);
            posted.value = true;
            toast.add({
              severity: 'success',
              summary: 'Успешно',
              detail: 'Успешно проведено',
              life: 3000,
            });
            ctx.emit('onSubmit');
          }
        } catch (error) {
          if (error instanceof CustomError) {
            error.show('error', 5000, toast);
          } else {
            console.log(error);
          }
        }
        loading.value = false;
      }
    };

    const unPostHandler = async () => {
      if (posted.value) {
        loading.value = true;
        try {
          if (item.id) {
            await api.unPost(item.id);
            posted.value = false;
            toast.add({
              severity: 'success',
              summary: 'Успешно',
              detail: 'Отмена проведения успешна',
              life: 3000,
            });
            ctx.emit('onSubmit');
          }
        } catch (error) {
          if (error instanceof CustomError) {
            error.show('error', 5000, toast);
          } else {
            console.log(error);
          }
        }
        loading.value = false;
      }
    };

    return {
      inputVisible,
      inputEditMode,
      saved,
      posted,
      loading,
      formStructure,
      item,
      fields,
      formHandler,
      fetch,
      onShow,
      resetFormHandler,
      submitHandler,
      savePostDocumentHandler,
      postHandler,
      unPostHandler,
    };
  },
});
