<template>
  <div class="root">
    <Card style="width: 100%">
      <template #content>
        Списание
        <WriteOffCatalog />
      </template>
    </Card>
  </div>
</template>

<script>
import Card from 'primevue/card';
import WriteOffCatalog from '@/components/WriteOff/WriteOffCatalog.vue';

export default {
  name: "WriteOff",
  components: {
    Card, WriteOffCatalog
  },
};
</script>

<style lang="scss" scoped>
.root {
  width: 100%;
  height: calc(100vh - 8rem);
}
</style>
