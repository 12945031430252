import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, withCtx as _withCtx, createSlots as _createSlots } from "vue"

const _hoisted_1 = { class: "p-d-flex p-dir-col" }
const _hoisted_2 = { class: "p-d-flex" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")
  const _component_flex_input = _resolveComponent("flex-input")
  const _component_writeoff_parts_list = _resolveComponent("writeoff-parts-list")
  const _component_writeoff_form_layout = _resolveComponent("writeoff-form-layout")
  const _component_Dialog = _resolveComponent("Dialog")

  return (_openBlock(), _createBlock(_component_Dialog, {
    visible: _ctx.inputVisible,
    "onUpdate:visible": _cache[3] || (_cache[3] = ($event: any) => (_ctx.inputVisible = $event)),
    style: { width: '100%' },
    contentStyle: { height: '100vh' },
    header: "Списание товаров",
    modal: true,
    closeOnEscape: false,
    class: "p-fluid",
    onShow: _ctx.onShow
  }, {
    default: _withCtx(() => [
      _createVNode("div", _hoisted_1, [
        _createVNode("div", _hoisted_2, [
          _createVNode(_component_Button, {
            label: "Провести и закрыть",
            class: "p-mr-1",
            onClick: _ctx.savePostDocumentHandler,
            style: {"width":"auto"},
            disabled: _ctx.posted || _ctx.loading
          }, null, 8, ["onClick", "disabled"]),
          _createVNode(_component_Button, {
            label: "Очистить",
            class: "p-button-secondary p-mr-3",
            onClick: _ctx.resetFormHandler,
            style: {"width":"auto"},
            disabled: _ctx.loading
          }, null, 8, ["onClick", "disabled"]),
          _createVNode(_component_Button, {
            icon: "pi pi-save",
            class: "p-button-secondary p-mr-1",
            onClick: _ctx.submitHandler,
            disabled: _ctx.posted || _ctx.loading
          }, null, 8, ["onClick", "disabled"]),
          (!_ctx.posted)
            ? (_openBlock(), _createBlock(_component_Button, {
                key: 0,
                disabled: !_ctx.saved || _ctx.loading,
                icon: "pi pi-chart-line",
                class: "p-button-success",
                onClick: _ctx.postHandler
              }, null, 8, ["disabled", "onClick"]))
            : _createCommentVNode("", true),
          (_ctx.posted)
            ? (_openBlock(), _createBlock(_component_Button, {
                key: 1,
                icon: "pi pi-chart-line",
                class: "p-button-danger",
                onClick: _ctx.unPostHandler,
                disabled: _ctx.loading
              }, null, 8, ["onClick", "disabled"]))
            : _createCommentVNode("", true)
        ]),
        _createVNode("div", null, [
          _createVNode(_component_writeoff_form_layout, null, _createSlots({
            lines: _withCtx(() => [
              _createVNode(_component_writeoff_parts_list, {
                loading: _ctx.loading,
                "onUpdate:loading": _cache[1] || (_cache[1] = ($event: any) => (_ctx.loading = $event)),
                modelValue: _ctx.item.lines,
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (_ctx.item.lines = $event))
              }, null, 8, ["loading", "modelValue"])
            ]),
            _: 2
          }, [
            _renderList(Object.keys(_ctx.formStructure), (slotName) => {
              return {
                name: slotName,
                fn: _withCtx(() => [
                  (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.formStructure[slotName], (key) => {
                    return (_openBlock(), _createBlock("div", { key: key }, [
                      _createVNode(_component_flex_input, {
                        modelValue: _ctx.item[key],
                        "onUpdate:modelValue": ($event: any) => (_ctx.item[key] = $event),
                        title: _ctx.fields[key].title,
                        id: key,
                        type: _ctx.fields[key].type,
                        variableType: _ctx.fields[key].variableType,
                        options: _ctx.fields[key].options,
                        invalid: _ctx.fields[key].invalid,
                        validationErrors: _ctx.fields[key].validationErrors,
                        onOnChange: ($event: any) => (_ctx.formHandler.checkValidation(key))
                      }, null, 8, ["modelValue", "onUpdate:modelValue", "title", "id", "type", "variableType", "options", "invalid", "validationErrors", "onOnChange"])
                    ]))
                  }), 128))
                ])
              }
            })
          ]), 1024)
        ])
      ])
    ]),
    _: 1
  }, 8, ["visible", "onShow"]))
}