import { IsNotEmpty } from 'class-validator';

import { Entity } from './Entity';
import { VALID_ERRORS } from '@/constants/errors';
import DOCUMENT_STATUS from '@/enums/document-status';
import { Part } from '@/classes/models/Part';

export interface WriteOffLine {
  id?: number;
  part?: Part;
  partId?: number;
  uom?: { id: number; name?: string };
  uomId?: number;
  quantity?: number;
  writeOffReason?: string;
}

interface WriteOffInterface {
  id?: number;
  documentDate?: Date;
  locationId?: number;
  location?: { id: number; name: string };
  status?: DOCUMENT_STATUS;
  lines?: WriteOffLine[];
}

export interface WriteOffs {
  currentPage: number;
  pageCount: number;
  pageSize: number;
  rowCount: number;
  firstRowOnPage: number;
  lastRowOnPage: number;
  results: WriteOff[];
}

export class WriteOff extends Entity<WriteOff> implements WriteOffInterface {
  id?: number;
  @IsNotEmpty({ message: VALID_ERRORS.required })
  documentDate?: Date;
  @IsNotEmpty({ message: VALID_ERRORS.required })
  locationId?: number;
  location?: { id: number; name: string };
  status?: DOCUMENT_STATUS;
  lines?: WriteOffLine[];

  constructor(data: WriteOffInterface) {
    super();
    this.replaceBy(data);
  }

  replaceBy(data: WriteOffInterface) {
    this.id = data.id || undefined;
    this.documentDate = data.documentDate || undefined;
    this.locationId =
      (data.location && data.location.id) || data.locationId || undefined;
    this.location = data.location || undefined;
    this.status = data.status || undefined;
    if (data.lines) {
      this.lines = data.lines.map(line => {
        return {
          id: line.id || undefined,
          partId: (line.part && line.part.id) || line.partId || undefined,
          part: line.part || undefined,
          uomId: (line.uom && line.uom.id) || line.uomId || 1,
          uom: line.uom || undefined,
          quantity: line.quantity || 1,
        };
      });
    } else {
      this.lines = [];
    }
  }
}
