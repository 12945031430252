<template>
  <div className="p-formgrid p-grid p-inputtext-sm" style="font-size: 1rem">
    <div className="p-col-6">
      <slot name="date"></slot>
    </div>
    <div className="p-col-6">
      <slot name="location"></slot>
    </div>
    <div className="p-col-12">
      <slot name="reason" />
    </div>
    <div className="p-col-12">
      <slot name="lines"></slot>
    </div>
    <Divider />
    <div className="p-col-12">
      <slot name="default"></slot>
    </div>
  </div>
</template>

<script>
import Divider from 'primevue/divider';

export default {
  name: 'WriteOffFormLayout',
  components: {
    Divider,
  },
};
</script>
