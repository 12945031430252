import { Api } from './Api';
import { WriteOff, WriteOffs } from '../models/WriteOff';
import CustomError from '../models/CustomError';

export class WriteOffApi extends Api {
  async fetchAll(page: number, pageSize: number): Promise<WriteOffs> {
    let sales: WriteOff[] = [];
    try {
      const result = await this.fetchAllProtected<WriteOffs>('docs/writeoff', {
        params: { page, pageSize },
      });
      if (result?.results?.length > 0) {
        sales = WriteOff.createFromArray(WriteOff, result.results);
      }
      return {
        ...result,
        results: [...sales],
      };
    } catch (error) {
      throw new CustomError(error.response);
    }
  }

  async fetchById(id: number): Promise<WriteOff> {
    try {
      const result = await this.fetchByIdProtected<WriteOff>('docs/writeoff', id);
      if (result) {
        return WriteOff.createFromObject(WriteOff, result);
      }
      return new WriteOff({});
    } catch (error) {
      throw new CustomError(error.response);
    }
  }

  async send(data: WriteOff): Promise<WriteOff> {
    try {
      const result = await this.sendProtected<WriteOff>('docs/writeoff', data);
      return WriteOff.createFromObject(WriteOff, result);
    } catch (error) {
      throw new CustomError(error.response);
    }
  }

  async update(data: WriteOff): Promise<WriteOff> {
    try {
      const result = await this.updateProtected('docs/writeoff', data);
      return WriteOff.createFromObject(WriteOff, result);
    } catch (error) {
      throw new CustomError(error.response);
    }
  }

  async remove(id: number) {
    try {
      await this.removeProtected('docs/writeoff', id);
    } catch (error) {
      throw new CustomError(error.response);
    }
  }

  async post(id: number): Promise<WriteOff> {
    try {
      const result = await this.postProtected<WriteOff>('docs/writeoff', id);
      if (result) {
        return WriteOff.createFromObject(WriteOff, result);
      }
      return new WriteOff({});
    } catch (error) {
      throw new CustomError(error.response);
    }
  }

  async unPost(id: number): Promise<WriteOff> {
    try {
      const result = await this.unPostProtected<WriteOff>('docs/writeoff', id);
      if (result) {
        return WriteOff.createFromObject(WriteOff, result);
      }
      return new WriteOff({});
    } catch (error) {
      throw new CustomError(error.response);
    }
  }
}
