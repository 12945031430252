import { FormFields } from '@/interfaces/Input';

const fields: FormFields = {
  documentDate: {
    title: 'Дата',
    type: 'date',
    slot: 'date',
  },
  locationId: {
    title: 'Склад',
    type: 'location',
    slot: 'location',
  },
  writeOffReason: {
    title: 'Причина списания',
    type: 'input',
    variableType: 'text',
    slot: 'reason'
  }
};

export default fields;
